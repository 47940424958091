import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import PageTemplate from 'templates/PageTemplate';
import { Helmet } from 'react-helmet';
import { SectionHeading } from 'components/SectionHeading/SectionHeading';
import { SectionParagraph } from 'components/SectionParagraph/SectionParagraph';
import { Button, ButtonText, ButtonIconArrow, ButtonsContainer } from 'components/Button/Button';
import { useIntl } from 'gatsby-plugin-intl';
import ArrowIcon from 'assets/arrow.svg';
import { useImageQuery } from '../hooks/use-image-query';
import HeroImage from '../components/HeroImage/HeroImage';
import { HeroSectionImage } from '../components/HeroImage/HeroImageStyles';
import Img from 'gatsby-image';
import * as Styled from '../components/Quote/QuoteStyles';
import QuoteIcon from '../assets/quote.svg';
import { ContentRow, ContentWrapper } from '../components/SectionContent/SectionContentStyles';

const Media: React.FC = () => {
  const { formatMessage, locale } = useIntl();
  const dataImage = useImageQuery();
  const { fluid } = dataImage.error_bg.localFile.childImageSharp;

  return (
    <PageTemplate>
      <HeroImage>
        <HeroSectionImage>
          <Img fluid={fluid} />
        </HeroSectionImage>
        <Styled.QuoteWrapper>
          <QuoteIcon />
          <Styled.QuoteContent notAnimate>{formatMessage({ id: 'quote.title' })}</Styled.QuoteContent>
        </Styled.QuoteWrapper>
      </HeroImage>
      <ContentWrapper>
        <ContentRow top>
          <Helmet htmlAttributes={{ lang: `${locale}` }}>
            <title>Agnieszka Kazmierczyk - 404</title>
            <meta name='description' content='Some content.' />
          </Helmet>
          <SectionHeading>Upss...</SectionHeading>
          <SectionParagraph>{formatMessage({ id: 'error.msg' })}</SectionParagraph>
          <ButtonsContainer>
            <Button as={Link} to={'/'} icon='true' aria-label={formatMessage({ id: 'home.title' })}>
              <ButtonText>{formatMessage({ id: 'home.title' })}</ButtonText>
              <ButtonIconArrow className='arrow--icon'>
                <ArrowIcon />
              </ButtonIconArrow>
            </Button>
          </ButtonsContainer>
        </ContentRow>
      </ContentWrapper>
    </PageTemplate>
  );
};

export default Media;
